<template>
  <div>
    <div class="header">
      <Header></Header>
    </div>
    <div class="banner">
      <Banner></Banner>
    </div>
    <div class="page-body">
      <div class="wrap">
        <div class="page-title">
          <em>公司介绍</em>
        </div>
        <div class="about-content flex">
          <div class="about-img">
            <img src="../assets/images/about_img.png" alt="" srcset="" />
          </div>
          <div class="about-text">
            <h3>芝兰互联网医院是在银川注册成立的正规医疗机构</h3>
            <p>
              通过移动端小程序，为患者提供在线问诊、用药指导、报告解读、早癌精准筛查、多学科会诊、健康档案、健康科普等服务；
            </p>
            <div class="about-line"></div>
            <p>
              同时，拥有HIPAA资质认证的呼叫中心，整合智能随访系统，为患者提供个性化、高质量随访。
              芝兰互联网医院以单病种诊疗为基础，以患者为中心，集结知名医师和药师，搭建“满足患者全周期健康管理需求”的慢病管理平台；
              同时致力于提供专业的工具帮助医生提高患者管理效率、减轻医生工作负担，最终实现对医院和患者的双向赋能。
            </p>
          </div>
        </div>
        <div class="page-title flex">
          <em>药品展示</em>
          <span class="more">
            <router-link to="/drugList">更多药品</router-link></span
          >
        </div>
        <div class="product-list flex">
          <div
            class="box"
            v-for="(item, index) in homeDrugList"
            :key="index"
            @click="drugDetail(index)"
          >
            <div class="pro-img">
              <img :src="item.coverImg" :alt="item.name" />
            </div>
            <div class="pro-title">{{ item.name }}</div>
            <div class="pro-info">
              {{ item.info }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Banner from "@/components/Banner.vue";
import Footer from "@/components/Footer.vue";

// 首页产品列表
import { drugList } from "@/products/drugList.js";
import { useRouter } from "vue-router";
export default {
  components: {
    Header,
    Banner,
    Footer,
  },
  setup() {
    const homeDrugList = drugList.slice(0, 6);

    const router = useRouter();
    const drugDetail = (index) => {
      router.push({
        path: "/drugDetails",
        query: {
          index,
        },
      });
    };
    return {
      homeDrugList,
      drugDetail,
    };
  },
};
</script>
<style lang="scss" scoped>
.about-content {
  .about-img {
    width: 45%;
    margin-right: 30px;
    img {
      width: 100%;
      height: auto;
    }
  }
  .about-text {
    width: 55%;
    padding-top: 65px;
    h3 {
      font-size: 24px;
      color: #333;
      font-weight: normal;
    }
    p {
      font-size: 16px;
      line-height: 1.8;
      margin: 20px 0;
    }
    .about-line {
      width: 140px;
      background-color: #00c6ff;
      height: 4px;
    }
  }
}

.product-list {
  justify-content: flex-start;
  .box {
    width: 190px;
    height: 250px;
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 25px 10px 0;
    margin-right: 12px;
    .pro-img {
      width: 150px;
      height: 120px;
      margin: 0 auto 10px;
      text-align: center;
      img {
        width: 100%;
        height: 115px;
      }
    }

    .pro-title {
      font-weight: bold;
      margin-bottom: 8px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .pro-info {
      font-size: 14px;
      height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #999;
    }
  }
  .box:hover {
    cursor: pointer;
  }
  .box:nth-child(6n) {
    margin-right: 0;
  }
}
</style>
