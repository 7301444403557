export const drugList = [{
    name: '999 感冒灵颗粒',
    info: '解热镇痛。用于感冒引起的头痛，发热，鼻塞，流涕，咽痛。',
    coverImg: require("@/assets/images/pro_01_0.jpg"),
    manualImg: require("@/assets/images/pro_01_1.jpg"),
    extend: {
        name: '999 感冒灵颗粒 10g*9袋',
        brand: '三九/999',
        size: '	10g*9袋',
        weight: '0.12kg',
        producer: '华润三九(枣庄)药业有限公司',
        serial: '国药准字Z44021940(国家食药局查询)',
        type: 'OTC（非处方药）'
    }
},
{
    name: '以岭 连花清瘟胶囊',
    info: '清瘟解毒，宣肺泄热。用于治疗流行性感冒属热毒袭肺证，症见：发热或高热，恶寒，肌肉酸痛，鼻塞流涕，咳嗽，头痛，咽干咽痛，舌偏红，苔黄或黄腻等。',
    coverImg: require("@/assets/images/pro_02_0.jpg"),
    manualImg: require("@/assets/images/pro_02_1.jpg"),
    extend: {
        name: '以岭 连花清瘟胶囊 0.35g*24粒',
        brand: '以岭',
        size: '0.35g*24粒',
        weight: '0.027kg',
        producer: '石家庄以岭药业股份有限公司',
        serial: '国药准字Z20040063',
        type: 'OTC（非处方药）'
    }
},
{
    name: '都乐 金嗓子喉片',
    info: '疏风清热，解毒利咽，芳香辟秽。适用于改善急性咽炎所致的咽喉肿痛，干燥灼热，声音嘶哑。',
    coverImg: require("@/assets/images/pro_03_0.jpg"),
    manualImg: require("@/assets/images/pro_03_1.jpg"),
    extend: {
        name: '都乐 金嗓子喉片 2g*6片*2板',
        brand: '都乐',
        size: '2g*6片*2板',
        weight: '0.04kg',
        producer: '广西金嗓子有限责任公司',
        serial: '国药准字B20020993',
        type: 'OTC（非处方药）'
    }
},
{
    name: '新乐敦 复方门冬维甘滴眼液',
    info: '用于抗眼疲劳，减轻结膜充血症状。 眼干眼涩 眼疲劳 减轻结膜充血症状',
    coverImg: require("@/assets/images/pro_04_0.jpg"),
    manualImg: require("@/assets/images/pro_04_1.jpg"),
    extend: {
        name: '新乐敦 复方门冬维甘滴眼液 13ml',
        brand: '新乐敦',
        size: '13ml',
        weight: '0.03kg',
        producer: '曼秀雷敦(中国)药业有限公司',
        serial: '国药准字H20020268',
        type: 'OTC（非处方药）'
    }
},
{
    name: '康富来 康富来无加糖型洋参含片',
    info: '【每日3次，每次1～2片】无加糖，成分高，片剂含服',
    coverImg: require("@/assets/images/pro_05_0.jpg"),
    manualImg: require("@/assets/images/pro_05_1.png"),
    extend: {
        name: '康富来 康富来无加糖型洋参含片',
        brand: '康富来',
        size: '0.6g*12片',
        weight: '0.03kg',
        producer: '	广东康富来药业有限公司',
        serial: '卫食健字（2000）第0024号',
        type: '其他'
    }
},
{
    name: '念慈庵 京都念慈菴枇杷糖',
    info: '念慈庵 京都念慈菴枇杷糖',
    coverImg: require("@/assets/images/pro_06_0.jpg"),
    manualImg: require("@/assets/images/pro_06_1.jpg"),
    extend: {
        name: '念慈庵 京都念慈菴枇杷糖 （纸盒）2.5g/粒',
        brand: '京都念慈菴',
        size: '（纸盒）2.5g/粒',
        weight: '0.028kg',
        producer: '泰国亚洲珍宝工业有限公司',
        serial: '卫食健进字（2000）第0065号',
        type: '其他'
    }
},
{
    name: '羚锐 通络祛痛膏',
    info: '活血通络，散寒除湿、消肿止痛。用于腰部、膝部骨性关节炎属瘀血停滞，寒湿阻络证，症见：关节刺痛或钝痛，关节僵硬，屈伸不利，畏寒肢冷。',
    coverImg: require("@/assets/images/pro_07_0.jpg"),
    manualImg: require("@/assets/images/pro_07_1.jpg"),
    extend: {
        name: '羚锐 通络祛痛膏 7cm*10cm*6贴',
        brand: '羚锐',
        size: '7cm*10cm*6贴',
        weight: '0.04kg',
        producer: '河南羚锐制药股份有限公司',
        serial: '国药准字Z20000065',
        type: 'OTC（非处方药）'
    }
},
{
    name: '云南白药 云南白药气雾剂',
    info: '活血散瘀，消肿止痛。用于跌打损伤，瘀血肿痛，肌肉酸痛及风湿疼痛。',
    coverImg: require("@/assets/images/pro_08_0.jpg"),
    manualImg: require("@/assets/images/pro_08_1.jpg"),
    extend: {
        name: '羚锐 通络祛痛膏 7cm*10cm*6贴',
        brand: '羚锐',
        size: '7cm*10cm*6贴',
        weight: '0.04kg',
        producer: '河南羚锐制药股份有限公司',
        serial: '国药准字Z20000065',
        type: 'OTC（非处方药）'
    }
},
{
    name: '江中 健胃消食片',
    info: '用于脾胃虚弱、消化不良、厌食症、胃胀腹胀【口服，可以咀嚼。成人一次3片，一日3次，小儿酌减】',
    coverImg: require("@/assets/images/pro_09_0.jpg"),
    manualImg: require("@/assets/images/pro_09_1.jpg"),
    extend: {
        name: '	江中 健胃消食片 0.8g*8片*4板(薄膜衣片)',
        brand: '江中',
        size: '0.8g*8片*4板(薄膜衣片)',
        weight: '0.04kg',
        producer: '江中药业股份有限公司',
        serial: '国药准字Z20013220',
        type: 'OTC（非处方药）'
    }
},
{
    name: '马应龙 马应龙麝香痔疮膏',
    info: '用于痔疮、肛裂',
    coverImg: require("@/assets/images/pro_10_0.jpg"),
    manualImg: require("@/assets/images/pro_10_1.jpg"),
    extend: {
        name: '马应龙 马应龙麝香痔疮膏 10g/支',
        brand: '马应龙',
        size: '10g/支',
        weight: '0.03kg',
        producer: '马应龙药业集团股份有限公司',
        serial: '国药准字Z42021920',
        type: 'OTC（非处方药）'
    }
},
{
    name: '快克 复方氨酚烷胺胶囊',
    info: '适用于缓解普通感冒及流行性感冒引起的发热、头痛、四肢酸痛、打喷嚏、流鼻涕、鼻塞、咽喉痛等症状，也可用于流行性感冒的预防和治疗。',
    coverImg: require("@/assets/images/pro_11_0.jpg"),
    manualImg: require("@/assets/images/pro_11_1.jpg"),
    extend: {
        name: '快克 复方氨酚烷胺胶囊 10粒',
        brand: '快克',
        size: '10粒',
        weight: '0.03kg',
        producer: '海南亚洲制药股份有限公司',
        serial: '国药准字H46020636',
        type: 'OTC（非处方药）'
    }
},
{
    name: '好立康 复方板蓝根颗粒 15g*10袋',
    info: '清热解毒，凉血。用于风热感冒，喉咙肿痛。',
    coverImg: require("@/assets/images/pro_12_0.jpg"),
    manualImg: require("@/assets/images/pro_12_1.jpg"),
    extend: {
        name: '好立康 复方板蓝根颗粒 15g*10袋',
        brand: '好立康',
        size: '10袋',
        weight: '0.17kg',
        producer: '广东一力罗定制药有限公司',
        serial: '国药准字Z45021100',
        type: 'OTC（非处方药）'
    }
}
]